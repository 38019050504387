import { onBeforeMount, onBeforeUnmount } from 'vue';
import { Packages } from '@/services/packages';
import { useSiteData } from '@/composables/useSiteData';
import { PageType } from '@/types';
import { ErrorCode } from '@/types/errors';
import { isSessionExpired } from '@/utils/session';
import { useTranslate } from '@shared/composable/useTranslate';
import type { Page } from '@shared/types/model';
import { $wait } from '@shared/utils/wait';
import { globalListener, globalRemoveListener } from '@shared/utils/helpers';
import { useDocLinks } from './useDocLinks';

export function usePaywall(page: Ref<Page>) {
  const domain = globalThis?.location?.origin;
  Packages.BASE_PATH = `${domain}/api/packages`;
  Packages.PACKAGE_BASE_PATH = `${domain}/api/package`;

  const { translate } = useTranslate();

  const { nextPage, language: locale, isPreview, isSandbox, countryCode } = useSiteData();

  const pageData = ref({
    packagesLoaded: false,
    packages: [],
    isSubmitLoading: false,
    isSubmitDisabled: false,
    docLinks: useDocLinks()
  });

  function checkCurrentSession() {
    if (isSessionExpired()) {
      globalThis?.location?.reload();
      return true;
    }

    return false;
  }

  function setSubmitLoading(isLoading = true) {
    pageData.value.isSubmitLoading = isLoading;
    pageData.value.isSubmitDisabled = isLoading;
  }

  async function handleSubmitPackage(packageId: number) {
    setSubmitLoading();
    if (isPreview.value && !isSandbox.value) return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
    try {
      checkCurrentSession();
      const { meta } = await Packages.selectPackage({ packageId }, {
        hideErrorToastFor: [ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR]
      });

      if (meta.httpStatus === 200) {
        setVisitedPages(PageType.PAYWALL);
        if (isSandbox.value) return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
        return goToPage(nextPage.value);
      }

      if (meta.errorCode === ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR) {
        setSubmitLoading(false);
        return showToast(translate('generate.templates.paymentSuccess.userAlreadySubscribedWarning', locale.value), '', 'error');
      }

      if (meta.errorCode) {
        setSubmitLoading(false);
      }
    } catch (ex) {
      setSubmitLoading(false);
      Promise.reject(ex);
    }
  }

  function setPackagesLoading(isLoading = true) {
    pageData.value.packagesLoaded = !isLoading;
    return isLoading ? $wait.start('initPackages') : $wait.end('initPackages');
  }

  async function getFlowPackages() {
    try {
      setPackagesLoading();
      const data = await Packages.getPackages();
      pageData.value.packages = data?.result?.packages || [];
    } catch (ex) {
      Promise.reject(ex);
    } finally {
      setPackagesLoading(false);
    }
  }

  function onPlanSelectionSubmitted(packageId:any) {
    handleSubmitPackage(packageId as number);
  }

  async function init() {
    getFlowPackages();
  }

  onBeforeMount(() => {
    globalListener('planSelectionSubmitted', onPlanSelectionSubmitted);
    init()
  });

  onBeforeUnmount(() => {
    globalRemoveListener('planSelectionSubmitted', onPlanSelectionSubmitted);
  });

  return { page, pageData };
}

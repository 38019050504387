import type { Page } from '@shared/types/model';
import { useGeneratedLinks } from '@/utils/useGeneratedLinks';
import { useSiteData } from '@/composables/useSiteData';
import { GTM, GA4 } from '@/utils/integrations';

export function useLanding(page: Ref<Page>) {
  const { siteData, isPreview } = useSiteData()

  useGeneratedLinks();

  const pageData = ref({
    docLinks: useDocLinks()
  })

  function homepageClick () {
    function clickEvent(event: any) {
      if (!siteData.value || isPreview.value) return false;

      GTM.push({
        event: 'customClick',
        clickCategory: 'Homepage',
        clickName: event.target.innerText
      });

      GA4.gtag('event', 'customClick', {
        clickCategory: 'Homepage',
        clickName: event.target.innerText
      })
    }

    const buttons = document.querySelectorAll('.tw-element-button, a[href]:not([href="#"]):not([href=""])');
    for (const button of buttons) {
      button.addEventListener('click', clickEvent);
    }
  }

  onMounted(() => {
    homepageClick()
  })

  return { page, pageData };
}

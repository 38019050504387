import { useSiteData } from '@/composables/useSiteData';
import { PageType } from "@/types";
import Register from '@/services/register';
import type { RegisterPayload, RegisterUserOptions } from '@/types/register';
import { ErrorCode } from "@/types/errors";
import { Facebook } from '@/utils/integrations';
import { handleUserAlreadySubscribedError } from '@/utils/handleUserAlreadySubscribed';
import { isSessionExpired } from '@/utils/session';
import { RegisterType } from '@/shared/types/model'
import { ConditionType, OTP_MAX_TIME, OTP_ERROR } from '@/shared/elements/common/registration/types';
import { clearToasts } from "@/utils/toast";

export function useRegisterCommon() {
  const domain = globalThis?.location?.origin;
  Register.BASE_PATH = `${domain}/api/`;

  const { isPreview, isSandbox, siteData, nextPage, countryCode } = useSiteData();

  const pageData = ref({
    isSubmitLoading: false,
    isSubmitDisabled: false,
    registerType: siteData.value?.registerType,
    countryCode: countryCode.value,
    phoneNumberAllowedCountries: siteData.value?.phoneNumberAllowedCountries,
    registerOtpStatus: siteData.value?.registerOtpStatus,
    activeCondition: ConditionType.REGISTER,
    remainingOtpSeconds: OTP_MAX_TIME,
    hasCheckOtpError: false,
  });

  const registerHasOtp = computed(() => pageData.value.registerOtpStatus);

  function checkCurrentSession() {
    if (isSessionExpired()) {
      globalThis?.location?.reload();
      return true;
    }

    return false;
  }

  function startLoading() {
    pageData.value.isSubmitLoading = true;
    pageData.value.isSubmitDisabled = true;
  }

  function endLoading() {
    pageData.value.isSubmitLoading = false;
    pageData.value.isSubmitDisabled = false;
  }

  function setActiveCondition(condition: ConditionType) {
    pageData.value.activeCondition = condition;
  }

  function setRemainingOtpSeconds(seconds = OTP_MAX_TIME) {
    pageData.value.remainingOtpSeconds = seconds;
  }

  function setOtpCondition() {
    if (!registerHasOtp.value) return;
    if (pageData.value.registerType === RegisterType.Email) return setActiveCondition(ConditionType.EMAIL_OTP);
    setActiveCondition(ConditionType.SMS_OTP);
  }

  function setHasCheckOtpError(value: boolean) {
    pageData.value.hasCheckOtpError = value;
  }

  async function registerUser(form: RegisterPayload, options?: RegisterUserOptions): Promise<boolean | Record<string, any>> {
    const { isFromSocialLoginCallback = false } = options || {};
    return new Promise((resolve, reject) => {
      if (isPreview.value && !isSandbox.value) {
        if (registerHasOtp.value && !isFromSocialLoginCallback) {
          setOtpCondition();
          return resolve(true);
        }
        goToPageWithPreviewCountry(nextPage.value, countryCode.value);
        return resolve(true);
      }

      checkCurrentSession();

      if (pageData.value.registerType === RegisterType.PhoneNumber) {
        form.subscriberId = (form.subscriberId && form.subscriberId.charAt(0) !== '+')
          ? `+${form.subscriberId}`
          : form.subscriberId;
      }

      Register.register(form, {
        hideErrorToastFor: [ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR]
      }).then((response) => {
        const { meta } = response;

        if (meta.httpStatus === 200) {
          if (!siteData.value?.registerOnPayment) {
            if (registerHasOtp.value && !isFromSocialLoginCallback) {
              resolve(response);
              return handleSendOtpCode();
            }
            handleRegisterComplete();
          }
          resolve(response);
        }

        if (meta.errorCode === ErrorCode.USER_ALREADY_SUBSCRIBED_ERROR) {
          const route = useRoute();
          const { slug } = getBaseUrl(route?.fullPath);
          handleUserAlreadySubscribedError(form?.subscriberId, slug);
          resolve(response);
        }

        if (meta.errorCode) reject(response);
      }).catch((res) => reject(res));
    });
  }

  function handleRegisterComplete() {
    Facebook.track('CompleteRegistration');
    setVisitedPages(PageType.REGISTER);
    if (isSandbox.value) return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
    goToPage(nextPage.value, { excludeQueryParams: ['provider', 'code'] });
  }

  async function handleSendOtpCode() {
    if (isSandbox.value) {
      setRemainingOtpSeconds();
      setOtpCondition();
      return;
    };
    clearToasts();
    startLoading();
    try {
      const { meta, result } = await Register.sendOtp({
        hideErrorToastFor: [OTP_ERROR.TIMEOUT_IS_NOT_FINISHED]
      });
      if (meta.errorCode === OTP_ERROR.REACHED_SENDING_OTP_LIMIT) return;
      if (meta.errorCode === OTP_ERROR.TIMEOUT_IS_NOT_FINISHED) {
        setRemainingOtpSeconds(result?.secondsToExpire);
        return setOtpCondition();
      }
      setRemainingOtpSeconds();
      setOtpCondition();
    } finally {
      endLoading();
    }
  }

  async function handleCheckOtpCode(code:string) {
    clearToasts();
    startLoading();
    if (isPreview.value) {
      setVisitedPages(PageType.REGISTER);
      return goToPageWithPreviewCountry(nextPage.value, countryCode.value);
    }
    try {
      const { meta } = await Register.checkOtp({ code });
      if (meta.errorCode) setHasCheckOtpError(true);
      if (meta.httpStatus >= 200 && meta.httpStatus < 300) handleRegisterComplete();
    } finally {
      endLoading();
    }
  }

  return {
    pageData,
    checkCurrentSession,
    registerUser,
    startLoading,
    endLoading,
    handleSendOtpCode,
    handleCheckOtpCode,
    handleRegisterComplete
  }
}
